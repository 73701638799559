/* Base styles for the gallery grid */
.gallery-grid {
    display: grid;
    grid-gap: 20px;
    row-gap: 6em;
    margin: auto;
  }
  
  /* 1 column for mobile (less than 768px) */
  @media (max-width: 767px) {
    .gallery-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* 2 columns for tablet (768px to 1023px) */
  @media (min-width: 768px) and (max-width: 1023px) {
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* 4 columns for desktop (1024px and above) */
  @media (min-width: 1024px) {
    .gallery-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* Styling individual items in the gallery */
  .gallery-item {
    overflow: hidden;
    padding-bottom: 4em;
    transition: transform 0.3s ease;
    position: relative;
  }
  .gallery-item:has(.parallax-index){
    padding-bottom: 4em;
  }
  .gallery-item .parallax-index{
    position: absolute;
    bottom:0;
    
  }
