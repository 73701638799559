/* define component style guide here */
:root {
  --primary-color: #181818;
  --secondary-color: #ffffff;
  --third-color: #d2d2d2;

  --font-family: 'Inter', sans-serif;

  --font-size-heading: 64px;
  --font-size-base: 16px;
  --font-size-heading-mobile: 56px;
}
h1{
  letter-spacing: -0.1em;
}

.grid-overlay{
  position: fixed;
  left:0;
  width: 100%;
  height:100%;
  background-image: url(/public/grid.png);
  background-size: cover;
  z-index: 9999;
  pointer-events: none;
}
@media only screen and (max-width:850px){
  .grid-overlay{
    z-index: 9999;
    background-image: url(/public/grid-Mobile.png);   
  }
}