.footer{

    background-color: white;
    width:100%;
    height:120px;
    
}
.footer img{
    height: 50px;
}
.footer-wrap{
    width:92%;
    margin:auto;
    margin-inline: 4%;
    display: flex;
    justify-content: space-between;

    gap:1.3%;
    align-items: center;
    
    height:100%;

}
.footer-wrap > div{
    width:24%;

}
.footer-wrap > div:first-child{
    width:76%
}
.footer-col-2, .footer-col-3, .footer-col-4{
    display: flex;
    flex-direction: column;
    transform:translateY(4px);
    position: relative;
}
.footer-col-2 a, .footer-col-3 a, .footer-col-4 a{
    cursor:pointer;
}
a, p{
    font-family: var(--font-family);
    font-weight:500;
    font-size: 16px;
}
@media only screen and (max-width:935px){
    .footer-wrap{
        width: 86%;
        margin-inline:6.5%;



            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 0px;

            min-height: 220px;
            padding-top:20px;
            

    }

    .footer-col-1{ grid-area: 1 / 1 / 2 / 2; }
    .footer-col-2 { grid-area: 1 / 2 / 2 / 3; }
    .footer-col-3 { grid-area: 1 / 3 / 2 / 4; }
    .footer-col-4 { grid-area: 2 / 1 / 3 / 4; }

    .all-button, .back-to-top{
        display: none !important;
    }

    .footer-wrap > div{
        width:100% !important;

    }
    .footer-col-2 {
        margin-left:20px;
    }
    .footer-col-4{
        padding-top: 2em;
        padding-bottom: 2em;
    }
    .footer-col-4 > a{
        text-align: center;

 
    }
}